import React from 'react';
import { Page } from '../../components/Page';
import { Section } from '../../components/Section';
import { Seo } from '../../components/Seo';
import { Slider } from '../../components/Slider';
import { ProjectCard } from '../../components/ProjectCard';
import { Button, ButtonType } from '../../components/Button';
import { ProjectsTemplateData } from '../Project/data';
import * as classes from './style.module.css';

interface ProjectsListingTemplateProps {
    pageContext: {
        projects: ProjectsTemplateData[];
    };
}

interface FilterOption {
    label: string;
    selected: boolean;
    relatedProjectsIds: string[];
}

export default function ProjectsListingTemplate(props: ProjectsListingTemplateProps): React.ReactElement {
    const PROJECTS_PER_PAGE = 9;
    const projects = props.pageContext.projects;
    const [filterOptions, setFilterOptions] = React.useState<FilterOption[]>(extractFilterOptions(projects));
    let [shownProjectsNumber, setShownProjectsNumber] = React.useState<number>(PROJECTS_PER_PAGE);

    function handleFilterOptionClick(optionLabel: string): void {
        const updatedFilterOptions = [...filterOptions];
        const selectedOptionIndex = updatedFilterOptions.map((o) => o.label).indexOf(optionLabel);
        updatedFilterOptions[selectedOptionIndex].selected = !updatedFilterOptions[selectedOptionIndex].selected;
        setFilterOptions(updatedFilterOptions);
    }

    function handleLoadMoreButtonClick(projectsNumber: number, selectedProjectsNumber?: number): void {
        let incrementedProjectNumber = shownProjectsNumber + 3;
        setShownProjectsNumber(incrementedProjectNumber);
    }

    // Check if at least one filter option is selected. If so, create an array of all project ids that
    // are selected based on the current filter option selection. We use this later on to easily check
    // which projects to show.
    let selectedProjectsIds: string[] = [];
    const filterSelected = filterOptions.map((o) => o.selected).indexOf(true) !== -1;
    if (filterSelected) {
        selectedProjectsIds = filterOptions
            .filter((option) => option.selected) // Filter only for selected options
            .map((option) => option.relatedProjectsIds) // Create an array of project ids arrays
            .flat(1) // Flatten the array to a string[]
            .filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicate project ids
    }

    return (
        <>
            <Seo title="Portfolio" useTitleTemplate={true} />
            <Page>
                <Section anchor="projectsListing" heading="Portfolio">
                    <div className={classes.Filter}>
                        Select categories to filter projects
                        <Slider additionalClasses={[classes.Options]}>
                            {filterOptions.map((option, key) => {
                                return (
                                    <div
                                        key={key}
                                        role="button"
                                        onClick={() => handleFilterOptionClick(option.label)}
                                        className={[
                                            classes.Option,
                                            option.selected === true ? classes.Selected : null,
                                        ].join(' ')}
                                    >
                                        {option.label} ({option.relatedProjectsIds.length})
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    <div className={classes.Listing}>
                        {projects
                            .filter((project) => !filterSelected || selectedProjectsIds.includes(project.id))
                            .slice(0, shownProjectsNumber)
                            .map((project, key) => {
                                return (
                                    <ProjectCard
                                        key={key}
                                        data={{
                                            image: project.banner,
                                            title: project.title,
                                            category: project.categories.join(' / '),
                                            publishedAt: new Date(project.date.replace(/-/g, '/')),
                                            link: project.slug,
                                            readingTime: project.readingTime.text,
                                        }}
                                    />
                                );
                            })}
                    </div>
                    {(filterSelected && selectedProjectsIds.length > shownProjectsNumber) ||
                    (!filterSelected && projects.length > shownProjectsNumber) ? (
                        <div className={classes.LoadMore}>
                            <Button
                                type={ButtonType.BUTTON}
                                label="Load More"
                                onClickHandler={() => 
                                    handleLoadMoreButtonClick(
                                        projects.length,
                                        filterSelected ? selectedProjectsIds.length : undefined,
                                    )
                                }
                            />
                        </div>
                    ) : null}
                </Section>
            </Page>
        </>
    );
}

// Helper function to calculate a sorted array of filter options based on the given projects
// We use the helper function before we initialize the state so that it can happen on the server.
function extractFilterOptions(projects: ProjectsTemplateData[]): FilterOption[] {
    const filterOptions: FilterOption[] = [];
    const categoryList: string[] = [];
    projects.forEach((project) => {
        project.categories.forEach((category) => {
            if (!categoryList.includes(category)) {
                filterOptions.push({ label: category, selected: false, relatedProjectsIds: [project.id] });
                categoryList.push(category);
            } else {
                const optionIndex = filterOptions.map((o) => o.label).indexOf(category);
                filterOptions[optionIndex].relatedProjectsIds.push(project.id);
            }
        });
    });
    return filterOptions.sort((a, b) => (a.relatedProjectsIds.length > b.relatedProjectsIds.length ? -1 : 1));
}
